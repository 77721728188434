<template>
    <v-container class="resetPassword">
        <div class="d-flex flex-row align-item-end justify-end mb-8">
            <router-link to="/login">
                <v-icon >
                {{ closePage }}
                </v-icon>
            </router-link>
        </div>
        <v-toolbar-title class="pb-5 ps-2">
            <h2 class="text-center">ー パスワードのリセット ー</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="text-center"><span>新しいパスワードを設定してください。</span></div>
        <v-spacer></v-spacer>
        <v-col class="pr-2 pb-2 pt-5" cols="12">
            <form @submit.prevent="handleResetPassword">
                <v-row>
                    <v-col class="pb-0 label-class" cols="12" md="2" xs2 >
                        <span >{{$t("message.label-email")}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs2 class="input-email pb-0  ">
                        <v-text-field
                            style="color: white; margin-top: 0px"
                            v-model="email"
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            required
                            outlined
                            background-color="white"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs2 class="label-class pb-0 ">
                        <span>{{$t("message.label-new-password")}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs2 class="input-class pb-0 ">
                        <v-text-field
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :error-messages="passwordErrors"
                            name="password"
                            autocomplete="on"
                            required
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            outlined
                            background-color="white"
                        />
                    </v-col>
                    <v-col cols="12" md="2" xs2 class="label-class pb-0 ">
                        <span>{{$t("message.label-conf-password")}}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs2 class="input-class pb-0 ">
                        <v-text-field
                            v-model="confirmPassword"
                            :type="showConPassword ? 'text' : 'password'"
                            :error-messages="confirmPasswordErrors"
                            name="password"
                            autocomplete="on"
                            required
                            @input="$v.confirmPassword.$touch()"
                            @blur="$v.confirmPassword.$touch()"
                            :append-icon="showConPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showConPassword = !showConPassword"
                            outlined
                            background-color="white"
                        />
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <div class="d-flex flex-row-reverse mt-3 me-0" tile>
                    <div class="m-2">
                        <v-btn color="#ff0090" class="white--text" type="submit" @click.prevent="handleResetPassword">
                        {{$t("message.btn-update")}}
                        </v-btn>
                    </div>
                </div>
            </form>
        </v-col>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mdiCloseThick } from "@mdi/js";
export default {
    namae: 'ResetPassword',
    mixins: [validationMixin],
    validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
    data: () => ({
        email: "",
        password: "",
        confirmPassword: "",
        showPassword: false,
        showConPassword: false,
        errMsg: "",
        closePage: mdiCloseThick,
    }),
    methods: {
        handleResetPassword() {
            // let url_params = window.location.search
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString)
            const token = urlParams.get('token')
            let payload = {
                email: this.email,
                password: this.password,
                confirmPassword: this.confirmPassword,
                token: token
            }
            this.$store.dispatch('auth_module/resetPassword', payload)
        } 
    },
    computed: {
        emailErrors() {
            let errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t("message.err-msg-email"));
            !this.$v.email.required && errors.push(this.$t("message.err-msg-email-required"));
            this.err = errors
            return errors;  
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLength &&
                errors.push(this.$t("message.err-msg-password-minLength"));
            !this.$v.password.required && errors.push(this.$t("message.err-msg-password-required"));
            return errors;
        },
        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.confirmPassword.$dirty) return errors;
            !this.$v.confirmPassword.sameAsPassword &&
                errors.push(this.$t("message.err-msg-confirm-password-same"));
            !this.$v.password.required && errors.push(this.$t("message.err-msg-confirm-password-required"));
            return errors;
        },
    },
    created() {

    }
}
</script>

<style>
.resetPassword {
  background-color: #D5D5D5;
  /* height: 100vh; */
  max-width: 100vw !important;
  height: 100%;
}
@media only screen and (max-width: 320px) {
  .register {
    background-color: #D5D5D5;
    /* height: 135vh; */
    /* background-color: darkblue;
    height: 100vh; */
    max-width: 100vw !important;
  }


}
.email-disable #input-7 {
  color: white !important;
}
.email-disable .v-input__slot::before {
  display: none;
}
/* .email-disable .v-input__slot::after {
  display: none;
} */
.email-disable .v-input__control .v-input__slot .v-text-field__slot input {
  color: white !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.input-email {
  padding-top: 0px;
}
/* .v-text-field__details {
  display: none;
} */
</style>